
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import Card from "primevue/card";
import { mapActions, mapState, mapGetters } from "vuex";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";
import AddShippingAddressDialog from "@/components/Pos/AddShippingAddressDialog.vue";
import Customer from "@/types/customer";
import Footer from "@/components/Footer.vue";
import AddressItems from "../UI/AddressItems.vue";
export default defineComponent({
  name: "ShipTo",
  props: {
    index: Number,
  },
  components: {
    InputText,
    Checkbox,
    Button,
    Divider,
    Dropdown,
    Dialog,
    MultiSelect,
    Footer,
    Card,
    AddShippingAddressDialog,
    AddressItems,
  },
  created() {
    if (this.getCustomer(this.index) != null) {
      const shipToAddress = this.getShipTo(this.index);
      this.shipToInfo = JSON.parse(JSON.stringify(shipToAddress)) || [];
    }
    if (this.getCustomer(this.index) != null) {
      this.fetchStax({
        client: this.getClient,
      });
    }
    if (this.getCustomer) {
      const map = this.getCustomerMap;
      const ind = this.index || 0;

      let original = map[ind].customer.carrier_items;
      let reformatted: Array<any> = [];
      original?.forEach((carrier: any) => {
        carrier.frt_account_items?.forEach((part: any) => {
          let partObject = {
            frt_account: part.frt_account,
            carrier: carrier.carrier,
          };
          reformatted.push(partObject);
        });
      });
      this.thirdPartyList = JSON.parse(JSON.stringify(reformatted));
    } else {
      return null;
    }
  },
  data() {
    return {
      shipToInfo: Array<any>(),
      showDialog: false,
      isEditing: false,
      isAddingAddress: false,
      currentSeq: "",
      shipToAddress: Object(),
      country: "",
      countryCode: "",
      shipToStreet: "",
      thirdPartyList: Array<any>(),
      deleteAddressDialog: false,
      isSubmitting: false, // FOR SAVE BUTTON LOADING STATE
      isValidating: false,
      validationItemIdx: -1,
    };
  },
  computed: {
    ...mapState(["customerInquiry", "session"]),
    ...mapGetters({
      getCustomer: "customerInquiry/getCustomer",
      getClient: "session/getClient",
      getCustomerMap: "customerInquiry/getCustomerMap",
      getCountriesData: "customerInquiry/getCountries",
      getStax: "customerInquiry/getStax",
      getShipTo: "customerInquiry/getShipTo",
    }),
    countriesData(): Array<any> {
      const countries = this.getCountriesData;
      return countries || [];
    },
    getTaxCodes(): Array<any> {
      const taxCodes = this.getStax;
      return taxCodes;
    },
    getName(): string {
      const map = this.getCustomerMap;
      const ind = this.index || 0;
      return map[ind].customer.name;
    },
    getCustomerId(): string {
      const map = this.getCustomerMap;
      const ind = this.index || 0;
      return map[ind].customer.cust_id;
    },
    showShipAddress(): boolean {
      return this.shipToInfo.length > 0 ? true : false;
    },
  },

  methods: {
    ...mapActions({
      fetchStax: "customerInquiry/getStax",
      validateCustomerAddress: "customerInquiry/validateCustomerAddress",
      updateCustomer: "customerInquiry/updateCustomer",
      updateField: "customerInquiry/updateField",
      syncCustomer: "customerInquiry/syncCustomer",
    }),
    isChangesSaved() {
      return (
        JSON.stringify(this.shipToInfo) ===
        JSON.stringify(this.getShipTo(this.index))
      );
    },
    // GETTING DATA METHODS
    getTaxCodeData() {
      this.getStax({
        client: this.getClient,
      });
    },
    getTaxCodeOptions() {
      const taxCodeOptions =
        this.getTaxCodes != null
          ? this.getTaxCodes.map((ele: any) => ele.id)
          : [];
      const distinctTaxCodeOptions = [...new Set(taxCodeOptions)];
      const taxCodes: Array<any> = [];
      for (let i = 0; i < distinctTaxCodeOptions.length; i++) {
        taxCodes.push({ ship_to_tax: distinctTaxCodeOptions[i] });
      }
      return taxCodes;
    },
    getCarrierOptions() {
      const carrierOptions =
        this.thirdPartyList != null
          ? this.thirdPartyList.map((element: any) => {
              return {
                ship_carrier: element.carrier,
                ship_acct_no: element.frt_account,
              };
            })
          : [];
      return carrierOptions;
    },
    getSequenceList() {
      const seqList = this.shipToInfo?.map((element: any) => element.ship_seq);
      return seqList || [];
    },
    getCountries() {
      const countryOptions = this.countriesData?.map(
        (element: any) => element.country,
      );
      return countryOptions;
    },
    getCountryCode(event: any): string {
      const country = this.countriesData?.find(
        (element: any) => element.country === event,
      );
      if (country) {
        this.countryCode = country.country_code;
        return this.countryCode;
      } else {
        return "";
      }
    },
    //UPDATING THE STREET FIELD IN SHIPTO ADDRESS OBJECT
    updateAddressObject(ship_seq: any) {
      this.shipToInfo.map((addressObject) => {
        if (addressObject.ship_seq == ship_seq) {
          addressObject.ship_to_items = [];
          addressObject.ship_to_items.push({ ship_to: this.shipToStreet });
        }
      });
    },
    //VALIDATION METHOD
    validate(address: any, index: number) {
      this.validationItemIdx = index;
      this.isValidating = true;
      this.validateCustomerAddress(address)
        .then(() => {
          this.isValidating = false;
        })
        .catch(() => {
          this.isValidating = false;
        });
    },
    emailValidation(email: any) {
      if (email == null) {
        return true;
      } else {
        return /^\S+@\S+\.\S+$/.test(email);
      }
    },
    //ADDRESS CRUD METHODS
    addAddress() {
      this.showDialog = true;
      this.shipToAddress = {};
      this.shipToStreet = "";
      this.isAddingAddress = true;
    },
    editAddress(shipToAddressObj: any) {
      this.showDialog = true;
      this.isEditing = true;
      this.isAddingAddress = false;
      this.shipToAddress = { ...shipToAddressObj };
      this.currentSeq = this.shipToAddress.ship_seq;
      this.country = this.shipToAddress.ship_country;
      this.getCountryCode(this.shipToAddress.ship_country);
    },
    async saveAddressInfo(newAddress: boolean, data?: any) {
      const custId = this.getCustomer(this.index).cust_id;
      let [oldCust, newCust] = [{}, {}];
      let oldShipSeq = this.getCustomer(this.index).ship_seq_items;
      if (!newAddress) {
        oldCust = {
          cust_id: custId,
          ship_seq_items: oldShipSeq,
        };
        newCust = {
          cust_id: custId,
          ship_seq_items: this.shipToInfo,
        };
      } else {
        const nextSeqItemId = (
          this.shipToInfo
            .map((element: any) => element.ship_seq)
            .reduce((a: any, b: any) => Math.max(a, b), -Infinity) + 1
        ).toString();

        data.ship_seq = nextSeqItemId;
        newCust = {
          cust_id: custId,
          ship_seq_items: [...this.shipToInfo, data],
        } as Customer;
        oldCust = {
          cust_id: custId,
          ship_seq_items: [...this.shipToInfo],
        };
      }

      await this.updateCustomer({
        custId: this.getCustomer(this.index).cust_id,
        oldCust,
        newCust,
        Client: this.getClient,
      })
        .then((response) => {
          this.isSubmitting = false;
          this.showDialog = false;
          this.isEditing = false;
          if (newAddress) {
            this.shipToInfo.push(data);
          }

          this.updateField({
            field: "SHIPTO",
            data: this.shipToInfo,
            index: this.index,
          });
          this.syncCustomer(this.getCustomer(this.index).cust_id);
        })
        .catch((response) => {
          this.isSubmitting = false;
          if (!newAddress) {
            this.showDialog = false;
            this.isEditing = true;
          } else {
            this.showDialog = true;
            this.isEditing = true;
          }
        });
    },
    updateShipAddress(newAddress: any, shipAddress: any) {
      shipAddress.ship_to_items = newAddress;
    },
    deleteAddress() {
      this.shipToInfo = this.shipToInfo.filter((element: any) => {
        return element.ship_seq !== this.currentSeq;
      });
      const oldCust = {
        cust_id: this.getCustomer(this.index).cust_id,
        ship_seq_items: this.getShipTo(this.index),
      };
      const newCust = {
        cust_id: this.getCustomer(this.index).cust_id,
        ship_seq_items: this.shipToInfo,
      };
      this.isSubmitting = true;
      this.updateCustomer({
        custId: this.getCustomer(this.index).cust_id,
        oldCust,
        newCust,
        Client: this.getClient,
      })
        .then((response) => {
          this.isSubmitting = false;
          this.updateField({
            field: "SHIPTO",
            data: this.shipToInfo,
            index: this.index,
          });
          this.syncCustomer(this.getCustomer(this.index).cust_id);
        })
        .catch((response) => {
          this.isSubmitting = false;
        });
      this.deleteAddressDialog = false;
    },

    confirmDeleteAddress(ship_sequence: any) {
      this.currentSeq = ship_sequence;
      this.deleteAddressDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.isEditing = false;
      this.deleteAddressDialog = false;
    },
  },
});
