
import { defineComponent } from "vue";
import store from "@/store";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import CustomerService from "@/services/CustomerService";
import AddressItems from "../UI/AddressItems.vue";
import TaxCodes from "../Sales/Orders/TaxCodes.vue";
import { mapActions } from "vuex";

import { ShipItem } from "@/types/customer";
const customerService = new CustomerService();

export default defineComponent({
  name: "AddShippingAddressDialog",

  components: {
    Dialog,
    InputText,
    Dropdown,
    Checkbox,
    MultiSelect,
    Button,
    InputSwitch,
    AddressItems,
    TaxCodes,
  },

  props: {
    header: {
      type: String,
      default: "Address",
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    addAddressToCustomerSwitch: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: String,
      default: "",
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    updateAddressLabel: {
      type: String,
      default: "Add address to customer?",
    },
    shipAddress: {
      type: ShipItem,
      default: () => new ShipItem(),
    },
  },
  mounted() {
    this.show = this.showDialog;
    this.ship.initFromShipItem(this.shipAddress);
  },
  computed: {
    countriesData(): any {
      const countries = store.getters["customerInquiry/getCountries"];
      return countries;
    },
    getTaxCodes(): any {
      const taxCodes = store.getters["customerInquiry/getStax"];
      return taxCodes;
    },
    shipTaxCodes(): any {
      return this.ship.ship_to_tax_items.map((ele: any) => {
        return {
          tax_codes: ele.ship_to_tax,
          tax_code_rate: ele.tax_code_rate,
        };
      });
    },
  },

  data() {
    return {
      thirdPartyList: Array<any>(),
      addAddressToCustomer: false,
      isSubmitting: false,
      countryCode: "",
      isSubmitted: false,
      show: false,
      newShippingAddressModified: false,
      ship: new ShipItem(),
    };
  },

  emits: ["closeDialog", "saveAddress"],

  watch: {
    async customerId(newVal) {
      if (newVal) {
        await this.fetchCustomerData();
      }
    },
    showDialog(newVal) {
      this.show = newVal;
      this.addAddressToCustomer = false;
    },
    shipAddress: {
      handler(newValue) {
        if (!this.newShippingAddressModified) {
          this.ship.initFromShipItem(newValue);
        }
      },
      deep: true,
    },
    editMode: {
      handler(newValue) {
        if (newValue === false) {
          this.clearNewShippingAddress();
        }
      },
    },
  },

  methods: {
    ...mapActions({
      addNotification: "notification/add",
    }),
    async fetchCustomerData() {
      try {
        await customerService
          .getCustomer(
            this.customerId,
            store.getters["session/getClient"],
            "saleopp_ids soquote_ids so_ids ticket_ids ar_ids rma_ids log_ids highest_ar_bal avg_ar_bal",
          )
          .then((response: any) => {
            let original = response.carrier_items;
            let reformatted: Array<any> = [];
            original?.forEach((carrier: any) => {
              carrier.frt_account_items?.forEach((part: any) => {
                let partObject = {
                  frt_account: part.frt_account,
                  carrier: carrier.carrier,
                };
                reformatted.push(partObject);
              });
            });
            this.thirdPartyList = JSON.parse(JSON.stringify(reformatted));
          });
      } catch (error) {
        this.addNotification({
          message: "Error fetching customer data",
          type: "error",
        });
      }
    },
    getCountries() {
      const countryOptions = this.countriesData?.map(
        (element: any) => element.country,
      );
      return countryOptions;
    },
    getCountryCode(event: any) {
      for (let i = 0; i < this.countriesData.length; i++) {
        if (event === this.countriesData[i].country) {
          this.countryCode = this.countriesData[i].country_code;
          return this.countryCode;
        }
      }
    },
    updateShipAddress(address: any) {
      this.onInputChange();
      this.ship.ship_to_items = address;
    },
    emailValidation(email: any) {
      if (email == null) {
        return true;
      } else {
        return /^\S+@\S+\.\S+$/.test(email);
      }
    },
    updateTaxCodes(taxCodes: any) {
      this.ship.ship_to_tax_items = taxCodes.map((ele: any) => {
        return { ship_to_tax: ele.tax_codes, tax_code_rate: ele.tax_code_rate };
      });
    },
    closeAddressDialog() {
      this.show = false;
      this.isSubmitted = false;
      this.$emit("closeDialog");
    },
    onInputChange() {
      if (this.editMode) {
        this.newShippingAddressModified = true;
      } else {
        this.newShippingAddressModified = false;
      }
    },
    saveAddressInfo() {
      this.isSubmitted = true;
      if (this.ship.ship_email && !this.emailValidation(this.ship.ship_email)) {
        return;
      }

      this.$emit("saveAddress", this.ship, this.addAddressToCustomer);
      this.closeAddressDialog();
      this.newShippingAddressModified = false;
    },
    clearNewShippingAddress() {
      this.ship = new ShipItem();
    },

    getTaxCodeOptions() {
      const taxCodeOptions =
        this.getTaxCodes != null
          ? this.getTaxCodes.map((ele: any) => ele.id)
          : [];
      const distinctTaxCodeOptions = [...new Set(taxCodeOptions)];
      const taxCodes: Array<any> = [];
      for (let i = 0; i < distinctTaxCodeOptions.length; i++) {
        taxCodes.push({ ship_to_tax: distinctTaxCodeOptions[i] });
      }
      return taxCodes;
    },

    getCarrierOptions() {
      const carrierOptions =
        this.thirdPartyList != null
          ? this.thirdPartyList.map((element: any) => {
              return {
                ship_carrier: element.carrier,
                ship_acct_no: element.frt_account,
              };
            })
          : [];
      return carrierOptions;
    },
  },
});
