import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid col-12 p-0 pl-2 m-0" }
const _hoisted_2 = { class: "grid col-12 p-0 m-0" }
const _hoisted_3 = { class: "flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_AddTaxDialog = _resolveComponent("AddTaxDialog")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DataTable, {
        value: _ctx.taxCodesItems,
        class: "p-datatable-sm w-12 text-sm font-semibold bordered-datatable",
        paginator: "",
        rows: 3,
        rowsPerPageOptions: [3, 5, 10],
        breakpoint: "800px"
      }, {
        empty: _withCtx(() => [
          _createTextVNode(" No records found ")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "tax_codes",
            header: "Tax Code",
            bodyClass: "text-center",
            headerClass: "general-label-color"
          }),
          _createVNode(_component_Column, {
            field: "tax_code_rate",
            header: "Percent",
            bodyClass: "text-center",
            headerClass: "general-label-color"
          }),
          _createVNode(_component_Column, null, {
            header: _withCtx(() => [
              _createVNode(_component_AddTaxDialog, { onAddTax: _ctx.addTax }, null, 8, ["onAddTax"])
            ]),
            body: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Button, {
                  icon: "pi pi-trash",
                  title: "Delete",
                  class: "mt-2 text-sm font-bold",
                  onClick: ($event: any) => (_ctx.deleteTax(slotProps.data.tax_codes))
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_ColumnGroup, { type: "footer" }, {
            default: _withCtx(() => [
              _createVNode(_component_Row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    footer: "",
                    footerStyle: "text-align:right"
                  }),
                  _createVNode(_component_Column, {
                    footer: 'Total: ' + _ctx.totalTaxPercent,
                    footerStyle: "text-align:center"
                  }, null, 8, ["footer"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ]))
}